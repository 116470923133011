export const toggleSelectAllRows = (
  employees,
  setSelectedRows,
  isAllVisibleRowsSelected,
) => {
  if (isAllVisibleRowsSelected) {
    setSelectedRows([]);
  } else {
    setSelectedRows(employees.map((employee) => employee.id));
  }
};
