import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Icon, Button, Badge, CheckBoxField } from "@unite-us/ui";
import SearchBar from "components/SearchBar/SearchBar";
import UsersTableFilters from "./components/UsersTableFilters";
import PaginatedTable from "components/Tables/PaginatedTable";
import InviteDialog from "./components/InviteDialog";
import MassEditUserModal from "./components/MassEditUserModal";
import SelectionText from "./components/SelectionText";
import { useEmployees } from "api/core/employeeHooks";
import { useAllRoles } from "api/core/roleHooks";
import {
  useCreateUserInvites,
  useCreateUserInvitesByEmployee,
} from "api/core/userInvitesHooks";
import { getEmployeeMatchingRoleNames } from "./utils/getEmployeeMatchingRoleNames.js";
import { toggleSelectAllRows } from "./utils/checkboxUtils";
import { constructUserInvitesPayload } from "./utils/constructUserInvitesPayload";
import { constructUserInvitesPayloadByEmployee } from "./utils/constructUserInvitesPayloadByEmployee";
import {
  getCommaSeparatedListLength,
  trimAndNormalizeSpaces,
} from "utils/string";
import { filterRoles } from "./utils/filterRoles";
import { getRolesIdsByKeys } from "./utils/getRolesIdsByKeys";
import TablePageContainerLayout from "layouts/TablePageContainerLayout";
import useLocalStorage from "hooks/useLocalStorage";

const Users = () => {
  const [filters, setFilters] = useLocalStorage("usersFilters", {});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [isLoadingInvite, setIsLoadingInvite] = useState(false);
  const userInvitesRef = useRef(null);
  const massEditUserRef = useRef(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [inviteAllUsersInOrgs, setInviteAllUsersInOrgs] = useState(false); // this controls if the select all text has been clicked or not

  useEffect(() => {
    setPageNumber(1);
  }, [filters]);

  useEffect(() => {
    massEditUserRef.current?.closeModal();
  });

  const { providerObjects, query, ...mainFilters } = filters;

  const { data: response, isFetching } = useEmployees({
    query: query || " ",
    filters: mainFilters,
    include: "provider",
    pageSize,
    pageNumber,
  });
  const { data: roles, isFetching: isFetchingRoles } = useAllRoles();
  const { createRecord: createUserInvites } = useCreateUserInvites();
  const { createRecord: createUserInvitesByEmployee } =
    useCreateUserInvitesByEmployee();

  const { data: employees, paging } = response;

  const employeesLength = employees.length;
  const isAllVisibleRowsSelected =
    selectedEmployees.length === employeesLength && employeesLength > 0;
  const providers = filters.provider;
  const isUsersSelected = selectedEmployees.length > 0;
  const providersLength = getCommaSeparatedListLength(providers);
  const isSelectedOrgsOutOfRange = providersLength > 20;

  const selectingById = isUsersSelected && !inviteAllUsersInOrgs;

  const filteredRoles = filterRoles(roles);
  const selectedRolesKeys = filters["roles.key"];
  const navigate = useNavigate();

  const cancelInviteHandler = () => userInvitesRef.current.closeDialog();
  const openInviteDialog = () => userInvitesRef.current.openDialog();
  const closeMassEditUserHandler = () => massEditUserRef.current.closeModal();
  const openMassEditUserModal = () => massEditUserRef.current.openModal();

  const confirmInviteHandler = async () => {
    setIsLoadingInvite(true);
    const selectedRolesIds = getRolesIdsByKeys(
      [...filteredRoles.primaryRoles, ...filteredRoles.featureRoles],
      selectedRolesKeys,
    );
    const payload = selectingById
      ? constructUserInvitesPayloadByEmployee(
          selectedEmployees.join(","),
          selectedRolesIds,
        )
      : constructUserInvitesPayload(filters.provider, selectedRolesIds);

    try {
      if (selectingById) {
        await createUserInvitesByEmployee(payload);
      } else {
        await createUserInvites(payload);
      }
    } catch (error) {
      console.error("Error sending user invites: ", error);
    } finally {
      setIsLoadingInvite(false);
      setInviteAllUsersInOrgs(false);
      setSelectedEmployees([]);
      userInvitesRef.current.closeDialog();
    }
  };

  useEffect(() => {
    setSelectedEmployees([]);
    setInviteAllUsersInOrgs(false);
  }, [
    filters.provider,
    filters.state,
    selectedRolesKeys,
    pageNumber,
    pageSize,
  ]);

  const handleOnSubmitSearchBar = (data) => {
    const searchTerm = data.search ? trimAndNormalizeSpaces(data.search) : "";
    setFilters({ ...filters, query: searchTerm });
  };

  const viewUserDetails = (employeeId) => navigate(`/users/${employeeId}`);

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="select-all-checkbox"
          label=""
          hideLabel
          showError={false}
          disabled={false}
          checked={isAllVisibleRowsSelected}
          onChange={() => {
            toggleSelectAllRows(
              employees,
              setSelectedEmployees,
              selectedEmployees.length === employeesLength,
            );
          }}
          style={{ container: { margin: "0px" } }}
        />
      ),
      className: "text-xs",
    },
    {
      label: "First Name",
      className:
        "text-left text-sm font-medium uppercase tracking-wider w-1/10 ",
    },
    {
      label: "Last Name",
      className:
        "text-left text-sm font-medium uppercase tracking-wider w-1/10",
    },
    {
      label: "Email",
      className: "text-left text-sm font-medium uppercase tracking-wider w-1/7",
    },
    {
      label: "Organization",
      className: "text-left text-sm font-medium uppercase tracking-wider w-1/8",
    },
    {
      label: "Primary Role",
      className: "text-left text-sm font-medium uppercase tracking-wider w-1/9",
    },
    {
      label: "Feature Role",
      className: "text-left text-sm font-medium uppercase tracking-wider w-1/3",
    },
    {
      label: "User Status",
      className:
        "text-left text-sm font-medium uppercase tracking-wider w-1/10",
    },
  ];

  const tableBody = employees.map((employee) => {
    const [primaryRole] = getEmployeeMatchingRoleNames(
      employee,
      filteredRoles.primaryRoles,
    );
    const featureRoles = getEmployeeMatchingRoleNames(
      employee,
      filteredRoles.featureRoles,
    );

    return {
      rowId: employee.id,
      rowData: [
        {
          data: (
            <CheckBoxField
              id={`select-checkbox-${employee.id}`}
              label=""
              hideLabel
              showError={false}
              disabled={false}
              checked={selectedEmployees.includes(employee.id)}
              style={{ container: { margin: "0px" } }}
              onChange={() => {
                setInviteAllUsersInOrgs(false);
                setSelectedEmployees((prevState) =>
                  prevState.includes(employee.id)
                    ? prevState.filter((id) => id !== employee.id)
                    : [...prevState, employee.id],
                );
              }}
            />
          ),
          onCellClick: (e) => {
            e.stopPropagation();
          },
          className: "text-xs",
        },
        {
          data: employee.first_name,
          className: "whitespace-nowrap text-xs capitalize",
        },
        {
          data: employee.last_name,
          className: "whitespace-nowrap text-xs capitalize",
        },
        { data: employee.email, className: "whitespace-nowrap text-xs" },
        {
          data: employee.provider?.name,
          className: "whitespace-nowrap text-xs capitalize",
        },
        {
          data: primaryRole,
          className: "whitespace-nowrap text-xs capitalize",
        },
        {
          data: (
            <div className="px-1 flex flex-wrap">
              {featureRoles.map((featureRole) =>
                featureRole.toLowerCase() === "none" ? (
                  <span className="px-2" key={`${employee.id}-none`}>
                    {featureRole}
                  </span>
                ) : (
                  <Badge
                    text={featureRole}
                    key={`${employee.id}-${featureRole}`}
                    className="h-5 text-sm md:text-xs my-1 mx-1 px-2 border border-filter-border-color rounded-md"
                    shape="square"
                    color="#E5EEF5"
                    style={{ paddingTop: "0.05rem", color: "black" }}
                  />
                ),
              )}
            </div>
          ),
          className: "whitespace-normal min-w-full p-0",
          style: { padding: 0 },
        },
        {
          data: employee.state,
          className: "whitespace-nowrap text-xs capitalize",
        },
      ],
    };
  });

  return (
    <TablePageContainerLayout>
      <div data-testid="users-header">
        <h1 className="w-12/12 flex-row pt-1 pb-2 font-bold text-brand-blue">
          Users
        </h1>
        <div className="flex">
          <div className="w-1/2 flex flex-row relative">
            <div className="md:w-3/4">
              <SearchBar
                initialValue={query}
                onSubmit={handleOnSubmitSearchBar}
                dataTestId="user-search-bar"
                placeholder="Search"
                delay={2000}
              />
            </div>
          </div>
          <div className="w-1/2 flex justify-end gap-x-4 items-center">
            <SelectionText
              inviteAllUsersInOrgs={inviteAllUsersInOrgs}
              selectedEmployees={selectedEmployees}
              isAllVisibleRowsSelected={isAllVisibleRowsSelected}
              allUsersSelected={
                paging?.total_count === selectedEmployees.length
              }
              paging={paging}
              setInviteAllUsersInOrgs={setInviteAllUsersInOrgs}
              providers={providers}
            />
            <Button
              id="invite-users-btn"
              iconLeft={<Icon color={"action-blue"} icon="V2User" />}
              className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
              label="Invite"
              data-testid="invite-users-btn"
              disabled={!isUsersSelected && !isSelectedOrgsOutOfRange}
              onClick={() => openInviteDialog()}
            />
            <Button
              id="edit-user-btn"
              iconLeft={<Icon color={"action-blue"} icon="IconPencil" />}
              className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
              label="Edit"
              data-testid="edit-users-btn"
              disabled={!isUsersSelected && !isSelectedOrgsOutOfRange}
              onClick={() => openMassEditUserModal()}
            />
          </div>
        </div>
        <UsersTableFilters
          filters={filters}
          setFilters={setFilters}
          filteredRoles={filteredRoles}
          isFetchingRoles={isFetchingRoles}
        />
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No Users Found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="users-table"
        onRowClick={viewUserDetails}
        isRowSelectable={true}
      />
      <InviteDialog
        ref={userInvitesRef}
        usersCount={
          selectingById ? selectedEmployees.length : paging?.total_count
        }
        isLoadingInvite={isLoadingInvite}
        cancelInviteHandler={cancelInviteHandler}
        confirmInviteHandler={confirmInviteHandler}
      />
      <MassEditUserModal
        modalRef={massEditUserRef}
        closeHandler={closeMassEditUserHandler}
        selectedUsersCount={
          inviteAllUsersInOrgs ? paging?.total_count : selectedEmployees.length
        }
        primaryRoles={filteredRoles.primaryRoles}
      />
    </TablePageContainerLayout>
  );
};

export default Users;
