import React from "react";
import PropTypes from "prop-types";
import Pagination from "components/Pagination/Pagination.js";
import CustomTable from "./CustomTable";

const PaginatedTable = (props) => {
  const {
    body,
    isFetching,
    pageSize,
    pageNumber,
    paging,
    setPageNumber,
    setPageSize,
    showSpinnerOnEmptyFetch,
  } = props;
  const showBody = showSpinnerOnEmptyFetch
    ? body.length > 0
    : !isFetching && body.length > 0;

  return (
    <>
      <CustomTable {...props} />

      <div className="pt-6 min-h-[66px]">
        {showBody && (
          <Pagination
            pageSize={pageSize}
            pageNumber={pageNumber}
            totalItemCount={paging.total_count}
            totalPageCount={paging.total_pages}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            showLabels={true}
            dataTestId={`${props.dataTestId}-pagination`}
          />
        )}
      </div>
    </>
  );
};

PaginatedTable.propTypes = {
  className: PropTypes.string,
  body: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.string.isRequired,
      rowData: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          className: PropTypes.string,
          style: PropTypes.object,
          onCellClick: PropTypes.func,
        }),
      ).isRequired,
    }),
  ),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      className: PropTypes.string,
      style: PropTypes.object,
    }),
  ),
  isFetching: PropTypes.bool,
  emptyTableMessage: PropTypes.string,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  paging: PropTypes.object,
  setPageNumber: PropTypes.func,
  setPageSize: PropTypes.func,
  dataTestId: PropTypes.string,
  onRowClick: PropTypes.func,
  isRowSelectable: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showSpinnerOnEmptyFetch: PropTypes.bool,
};

PaginatedTable.defaultProps = {
  className: undefined,
  body: [],
  headers: [],
  isFetching: false,
  emptyTableMessage: "No data found",
  pageSize: 10,
  pageNumber: 1,
  paging: {},
  setPageNumber: () => {},
  setPageSize: () => {},
  dataTestId: "paginated-table",
  isRowSelectable: false,
  onRowClick: () => {},
  isError: false,
  errorMessage: "Error fetching data.",
  showSpinnerOnEmptyFetch: false,
};

export default PaginatedTable;
